<template>
  <div style="font-size: 13px">
    <div v-if="data">
      <el-card class="information">
        <p>{{ $t("purchase.basic") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8">
            {{ $t("purchase.productName") }}: {{ data.productName }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.category") }}: {{ data.categoryName }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.purchaseType") }}: {{ data.purchaseType }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.purpose") }}: {{ data.procurementPurpose }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.quantity") }}: {{ data.quantity }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.tradeTerms") }}: {{ data.tradeTerms }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.maximumBudget") }} :
            {{ data.maximunBudget }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.detailed") }} :
            {{ data.detailDescription }}</el-col
          >
        </el-row>
        <el-row class="mt file">
          <el-col :span="8"
            ><img src="@/assets/Frame_21.png" /><span>{{
              $t("purchase.file")
            }}</span></el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.supplier") }}</p>
      </el-card>
      <el-card v-if="data.supplierRequire">
        <el-row>
          <el-col :span="8"
            >{{ $t("purchase.supplier") }} :
            {{ data.supplierRequire.businessType }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.product") }} :
            {{ data.supplierRequire.productionCert }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.business") }} :
            {{ data.supplierRequire.businessCert }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.testReport") }} :
            {{ data.supplierRequire.testReport }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.other") }} :
            {{ data.supplierRequire.otherRequire }}</el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.transportation") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8"
            >{{ $t("purchase.transportationMethod") }} :
            {{ data.transportMethod }}</el-col
          >
          <el-col :span="16"
            >{{ $t("purchase.destination") }} : {{ data.destination }}</el-col
          >
        </el-row>
        <el-row class="mt">
          <el-col :span="8"
            >{{ $t("purchase.payment") }} : {{ data.paymentMethos }}</el-col
          >
        </el-row>
      </el-card>

      <el-card class="information mt">
        <p>{{ $t("purchase.purchaser") }}</p>
      </el-card>
      <el-card>
        <el-row>
          <el-col :span="8" class="purchaser">
            <img src="@/assets/Ellipse_81.png" />
            <p>{{ $t("purchase.publisher") }} : {{ data.userName }}</p>
            <div class="clear"></div>
          </el-col>
          <el-col :span="16" class="mt"
            >{{ $t("purchase.releaseTime") }} : {{ this.createdAt }}</el-col
          >
        </el-row>
      </el-card>
      <el-card class="button mt">
        <el-button class="btn" @click="offer(data._id)">{{
          $t("purchase.offer")
        }}</el-button>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getData } from "../../helper/api";
const moment = require("moment");
export default {
  data() {
    return {
      data: [],
      createdAt: "",
    };
  },
  created() {
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      getData("GET", "purchase", id).then((res) => {
        this.data = res.data;
        console.log(this.data);
        this.createdAt = moment(this.data.createdAt).format("YYYY/MM/DD HH:mm");
      });
      
    },

    offer(id) {
      this.$router.push({
        path: "/purchase/quotation?id=" + id,
        query: {
          id
        },
      });

      // this.$router.push({
      //   path: "/purchase/purchaseDetail?id=" + id,
      //   query: {
      //     id,
      //   },
    },
  },
};
</script>

<style scoped>
.information {
  font-size: 14px;
  font-weight: 700;
  color: #7c4919;
  background: #ffdcbc;
  border-radius: 15px 15px 0 0;
}
.file img {
  width: 11px;
  height: 11px;
}
.file span {
  color: #ff0a0a;
  margin-left: 5px;
}
.purchaser img {
  width: 45px;
  height: 45px;
  float: left;
}
.purchaser p {
  float: left;
  margin-left: 10px;
  margin-top: 15px;
}
.button {
  text-align: center;
}
.button /deep/ .el-card__body {
  padding: 0;
}
.button .btn {
  width: 131px;
  height: 80px;
  color: #fff;
  background: #ff0a0a;
  font-size: 20px;
}
</style>